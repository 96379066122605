import React, { useCallback, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation, Link } from 'react-router-dom'

import i18n from '../util/localization'

type HeaderProps = {
  language: string
}

const Header = ({ language }: HeaderProps) => {
  const location = useLocation()
  const applicationName = ENV_VAR_APPLICATION === 'ENVIRONMENTAL' ? 'Miljörapport' : 'Uppföljningsrapport'
  const environmentName = (ENV_VAR_APP_ENV && ENV_VAR_APP_ENV === 'production') ? 'prod' : 'test'

  const [isTokenDispatched, setIsTokenDispatched] = useState(false)

  const { initialized, keycloak } = useKeycloak()
  const loggedIn = !!(keycloak?.authenticated && keycloak.token)
  const comingFromMagicLink = sessionStorage.getItem('token_type')

  const tryLogout = useCallback(
    (e: any) => {
      e.preventDefault()
      if (keycloak && initialized) {
        keycloak.logout()
        sessionStorage.clear()
      }
    },
    [initialized, keycloak]
  )

  const goToPrivacyPage = () => {
    window.open(`${ENV_VAR_MY_SCANIA_LINK}#/privacy`, '_blank')
  }

  const goToLoginPage = () => {
    window.open(`${ENV_VAR_MY_SCANIA_LINK}#/start`, '_blank')
  }

  useEffect(() => {
    const updateNavbarToken = (event: any) => {
      if (event.detail === true && loggedIn) {
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent('navbar_UpdateToken', {
              detail: keycloak.token
            })
          )
          alert('dispatched fg token @5.3.1')
          setIsTokenDispatched(true)
        }, 100)
      }
    }

    document.addEventListener('navbarHeaderReady', updateNavbarToken)

    return () => {
      document.removeEventListener('navbarHeaderReady', updateNavbarToken)
    }
  }, [keycloak?.token, loggedIn])

  useEffect(() => {
    window.addEventListener('navbarAccountLogOut', tryLogout)

    return () => {
      window.removeEventListener('navbarAccountLogOut', tryLogout)
    }
  }, [tryLogout])

  useEffect(() => {
    if (location.pathname.includes('/settings/subscribers')) {
      document.getElementById('reports_nav_menu_item')?.classList?.remove('active')
      document.getElementById('subscribers_nav_menu_item')?.classList?.add('active')
    } else {
      document.getElementById('subscribers_nav_menu_item')?.classList?.remove('active')
      document.getElementById('reports_nav_menu_item')?.classList?.add('active')
    }
  })

  return (
    <sss-navbar-header culture={language} environment={environmentName}>
      <sss-navbar-application-brand>
        <span>{i18n.t(applicationName)}</span>
      </sss-navbar-application-brand>
      <sss-navbar-top-menu>
        { ENV_VAR_APPLICATION === 'MONITORING' &&
          <>
            <sss-navbar-menu-item id='reports_nav_menu_item'>
              <Link to='/'>{i18n.t('Rapporter')}</Link>
            </sss-navbar-menu-item>
            { !comingFromMagicLink &&
            <sss-navbar-menu-item id='subscribers_nav_menu_item'>
              <Link to='/settings/subscribers'>{i18n.t('E_Postmottagare')}</Link>
            </sss-navbar-menu-item>
            }
          </>
        }
      </sss-navbar-top-menu>

      <div className='sss-navbar-menu-divider'></div>

      <div>
        {loggedIn && (
          <sss-navbar-header-navigation></sss-navbar-header-navigation>
        )}
      </div>
      <div>
        {loggedIn && isTokenDispatched && (
          <sss-navbar-tour-header
            environment={environmentName}
            show-reset={true}></sss-navbar-tour-header>
        )}
      </div>
      <div>
        {loggedIn && isTokenDispatched && (
          <navbar-header-notifications
            label={i18n.t('Aviseringar')}
            heading={i18n.t('Aviseringar')}>
            <notifications-container
              culturecode={language}
              loggedin={loggedIn}
              environment={environmentName}
              systemname='FmsVehiclePerformance'></notifications-container>
          </navbar-header-notifications>
        )}
      </div>

      <div>
        <sss-navbar-help></sss-navbar-help>
      </div>
      {loggedIn
        ? <div><sss-navbar-app-launcher></sss-navbar-app-launcher></div>
        : <sss-navbar-application-brand>
            <span className='clickable' onClick={goToLoginPage}>{i18n.t('LoggaIn')}</span>
          </sss-navbar-application-brand>}
      {loggedIn &&
        <div>
          <sss-navbar-account>
            <li
              className='sss-navbar__list-group-item sss-navbar__dropdown-item'
              onClick={goToPrivacyPage}>
              <span>{i18n.t('Gdpr_Integritetspolicy')}</span>
            </li>
          </sss-navbar-account>
        </div>
      }

      <sss-navbar-brand></sss-navbar-brand>
    </sss-navbar-header>
  )
}

export default Header
